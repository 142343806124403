<template>
  <div id="app">
      <div>
          <div class="top-info-wrapper">
              <span class="contact">咨询热线 17771371999</span>
              <a href="tel:17771371999">
                <van-button type="primary" hairline size="small" icon="phone-o" color="#262626"> 拨打 </van-button>
              </a>
          </div>
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
              <van-swipe-item><img src="@/assets/banner1.png" class="banner-img"/></van-swipe-item>
              <van-swipe-item><img src="@/assets/banner2.jpg" class="banner-img"/></van-swipe-item>
              <van-swipe-item><img src="@/assets/banner3.jpg" class="banner-img"/></van-swipe-item>
          </van-swipe>
      </div>
      <div class="form-wrapper" id="form">
          <div class="title">您需要咨询或购买可留下联系方式，24小时内我们会有专人联系您。</div>
          <div class="form">
              <van-form @submit="onSubmit">
                  <van-field
                      class="form-field"
                      v-model="formData.name"
                      name="name"
                      label="姓名"
                      placeholder="请输入您的姓名"
                      :rules="[{ required: true, message: '请填写姓名' }]"
                  />
                  <van-field
                      class="form-field"
                      v-model="formData.phone"
                      type="phone"
                      name="电话"
                      label="电话"
                      placeholder="请输入您的电话"
                      :rules="[{ required: true, message: '请填写电话' }]"
                  />
                  <van-field
                      class="form-field"
                      v-model="formData.address"
                      type="address"
                      name="地址"
                      label="地址"
                      placeholder="请输入您的地址"
                      :rules="[{ required: true, message: '请填写地址' }]"
                  />
                  <div style="margin: 16px;">
                      <van-button round block color="#FDE167" icon="success" type="info" native-type="submit" :loading="loading">提交</van-button>
                  </div>
              </van-form>
          </div>
      </div>
      <div>
          <img src="@/assets/open.png" class="content-img"/>
          <img src="@/assets/c1.jpg" class="content-img"/>
          <img src="@/assets/c2.png" class="content-img"/>
          <div class="product-wrapper">
              <div style="width: 100%;font-size: 24px;color: #ffffff;text-align: center;margin-bottom: 24px;">产品展示</div>
              <div class="product-img">
                  <img src="@/assets/p1.jpg"/>
                  <div style="color: #ffffff;font-size: 16px;padding:6px 0px 20px 0px;text-align: center;">收钱吧音箱</div>
              </div>
              <div class="product-img">
                  <img src="@/assets/p2.jpg"/>
                  <div style="color: #ffffff;font-size: 16px;padding:6px 0px 20px 0px;text-align: center;">收钱吧打印机</div>
              </div>
              <div class="product-img">
                  <img src="@/assets/p3.jpg"/>
                  <div style="color: #ffffff;font-size: 16px;padding:6px 0px 20px 0px;text-align: center;">收钱吧扫码王</div>
              </div>
              <div class="product-img">
                  <img src="@/assets/p4.png"/>
                  <div style="color: #ffffff;font-size: 16px;padding:6px 0px 20px 0px;text-align: center;">收钱吧收银机</div>
              </div>
          </div>
        <img src="@/assets/c3.png" class="content-img"/>
        <img src="@/assets/c4.png" class="content-img"/>
          <img src="@/assets/c5.png" class="content-img"/>
      </div>
      <div class="footer-wrapper" v-show="isVisable">
          <div class="left">
              <a href="tel:17771371999">
                <van-button icon="phone-o" block type="primary" color="#262626">拨打17771371999</van-button>
              </a>
          </div>
          <div class="right">
              <a href="#form">
                <van-button icon="fire-o" block type="primary" color="#262626">预约开通</van-button>
              </a>
          </div>
      </div>
  </div>
</template>

<script>

import axios from "axios";
import {Toast} from "vant";

export default {
  name: 'App',
  components: {
  },
    data () {
      return {
          isVisable: false,
          loading: false,
          formData: {
              name: null,
              phone: null,
              address: null
          }
      }
    },
    destroyed() {
        //离开页面时删除该监听
        window.removeEventListener('scroll', this.windowScrollListener)
    },
    created() {
        //添加滚动监听事件
        //在窗口滚动时调用监听窗口滚动方法
        window.addEventListener('scroll', this.windowScrollListener);
    },
    methods: {
        onSubmit () {

            this.loading = true
            let that = this
            axios.post(`/api/mobile/adorder`, this.formData).then(res => {
                if (res.status === 200 && res.data.code === 0 && res.data.ok) {
                    Toast('操作成功')
                    that.formData.name = null
                    that.formData.phone = null
                    that.formData.address = null
                } else {
                    Toast(res.msg)
                }
            }).finally(()=>{
                this.loading = false
            })
        },
        //监听窗口滚动
        windowScrollListener() {
            //获取操作元素最顶端到页面顶端的垂直距离
            var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            if (scrollTop >= 480) {
                this.isVisable = true; //大于480时显示元素
            }
            if (scrollTop < 480) {
                this.isVisable = false; //小于480时隐藏元素
            }
        }
    }
}
</script>

<style>
#app {
    min-height: 100vh;
    padding-bottom: 70px;
}
.banner-img {
    width: 100%;
    vertical-align: middle;
}
.top-info-wrapper .contact {
    font-weight: bold;
    font-size: 20px;
    color: #262626;
}
.top-info-wrapper {
    background: #FDE167;
    padding: 16px 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    //border-top: 2px solid #250c85;
}
.content-img {
    width: 100%;
    vertical-align: middle;
}
.footer-wrapper {
    position: fixed;
    bottom:0px;
    left:0px;
    width: 100%;
    display: flex;
    align-items: center;
    background: #FDE167;
    height: 70px;
}
.footer-wrapper .left, .footer-wrapper .right {
    width: 50%;
    padding: 12px;
}
.form-wrapper{
    padding: 50px 24px 20px 24px;
    background: #21201e;
}
.form-wrapper .title {
    font-size: 18px;
    color: #FDE167;
    text-align: center;
}
.form {
    margin-top: 30px;
}
.form-field {
    background: #ffffff !important;
    margin-bottom: 10px;
    border-radius: 6px;
}
.product-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 10px 30px 10px;
    background: #262626;
}
.product-img {
    width: 48%;
    vertical-align: middle;
    margin: 1%;
    box-sizing: border-box;
}
.product-img img {
    width: 100%;
    border-radius: 4px;
}
</style>
